.error {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  color: red;
}

.hint {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #b2b2b2;
}

.required {
  margin-left: 2px;
}

.selector {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  /* min-width: 7em !important; */
  text-align: center !important;
  padding: 9px 30px 9px 16px !important;
  min-height: 1em !important;
  width: 100%;
}
