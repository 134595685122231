.error {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  color: red;
}

.rootContainer {
  display: flex;
  flex-direction: row;
}

.datePicker {
  width: 100% !important;
  height: 38px !important;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 0 !important;
  padding: 6px 6px !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #fff !important;
  color: black !important;
  cursor: pointer !important;
}
.datePicker:focus {
  outline: none !important;
}

.greyedOut {
  color: #ccc !important;
}

.iconButton {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.icon {
  height: 30px;
  width: 30px;
  background: url("../../../../assets/images/printemps_agenda.png") center/36px
    36px no-repeat;
}

/* react-datepicker override */
:global(.react-datepicker-wrapper) {
  flex: 1 !important;
}

:global(.react-datepicker-popper) {
  border: 1px solid #d3d3d3 !important;
}

:global(.react-datepicker) {
  border: 0 !important;
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem !important;
}

:global(.react-datepicker__current-month) {
  font-size: 1em !important;
}

:global(.react-datepicker__month) {
  margin: unset !important;
}

:global(.react-datepicker__week) {
  display: flex !important;
  flex-direction: row !important;
}

:global(.react-datepicker__day-names) {
  display: flex !important;
  flex-direction: row !important;
}

:global(.react-datepicker__day-name), .day {
  width: unset !important;
  flex: 1 !important;
  padding: 1rem 1.2rem !important;
  border-radius: 0 !important;
}

@media (max-width: 767px) {
  :global(.react-datepicker) {
    font-size: 1.6rem !important;
  }
}