.desktopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hint {
  font-size: 1.2rem;
  color: #b3b3b3;
}

.altHint {
  margin-left: 10px;
  font-family: UrbanistBold, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  color: lightgrey;
}

.error {
  color: red;
  font-size: 1.2rem;
}

.uploadBtn {
  width: 36px;
  height: 30px;
  background: url("../../../../assets/images/printemps_upload.png")
    center/40px 40px no-repeat;
  border: none;
  cursor: pointer;
}
