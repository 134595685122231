.customCalendar {
    width: 100%;
    border: none;
}

.customCalendar > div {
    width: 100%;
}

.weekDay {
    width: calc((100% / 7) - (0.166rem * 2));
    font-weight: bold;
    text-transform: uppercase;
}

.customCalendarHeader {
    width: 100%;
}

.customCalendarHeader + div {
    display: none;
}

.customMonths {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0 1.5rem 0;
}

.customMonths > .monthLabel {
    font-weight: normal;
    text-transform: uppercase;
}

.customMonths > .decreaseMonth,
.customMonths > .increaseMonth {
    cursor: pointer;
    text-indent: unset;
    overflow: unset;
    border: none;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
}
.customMonths > .decreaseMonth:hover,
.customMonths > .increaseMonth:hover {
    background-color: #e7e7e7;
}

.customMonths > .decreaseMonth {
    left: 0;
}

.customMonths > .increaseMonth {
    right: 0;
}

.customCalendar
    > div:global(.react-datepicker__month-container)
    > div:global(.react-datepicker__header) {
    background-color: white;
    border-color: white;
    padding: 0px;
}

.customCalendar div:global(.react-datepicker__day-names) {
    border-top: solid 1px black;
    padding: 0.25rem 0 0.25rem 0;
}

.customCalendar div:global(.react-datepicker__day) {
    height: 32px;
    width: calc((100% / 7) - (0.166rem * 2));
    display: flex;
    justify-content: center;
    align-items: center;
}

.customCalendar div:global(.react-datepicker__day):hover {
    border-radius: 9999px;
    background-color: #e5e5e5;
}

.customCalendar div:global(.react-datepicker__day--keyboard-selected),
.customCalendar div:global(.react-datepicker__day--selected) {
    border: solid 1px black;
    border-radius: 9999px;
    color: black;
    background-color: #fbebe4;
}

.customCalendar div:global(.react-datepicker__day--disabled),
.customCalendar div:global(.react-datepicker__day--disabled) {
    cursor: not-allowed;
    background-color: white !important;
    text-decoration: line-through;
    text-decoration-thickness: 0.2rem;
}

.customCalendar div:global(.react-datepicker__week) {
    font-weight: 600;
}