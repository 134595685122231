.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.input {
  flex-grow: 1;
}

.toggleButton {
  position: absolute;
  right: 0;
  border: 1px solid lightgrey;
  background: url("./../../../../assets/images/printemps_eye.png")
    center/30px 30px no-repeat;
  border: none;
  min-height: 38px;
  min-width: 38px;
  cursor: pointer;
  opacity: 0.4;
}
