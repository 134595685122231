.error {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  color: red;
}

.hint {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #b2b2b2;
}

.required {
  margin-left: 2px;
}

.input {
  width: 100%;
  padding: 10px;
  text-align: left !important;
}

.input:focus {
  outline: none;
}

.lightgray textarea::-webkit-input-placeholder {
  color: lightgray;
}
.lightgray textarea:-moz-placeholder { /* Firefox 18- */
  color: lightgray;  
}
.lightgray textarea::-moz-placeholder {  /* Firefox 19+ */
  color: lightgray;  
}
.lightgray textarea:-ms-input-placeholder {
  color: lightgray;  
}
.lightgray textarea::placeholder {
  color: lightgray;  
}

.lightgray textarea:focus::-webkit-input-placeholder {
  color: gray;
}
.lightgray textarea:focus:-moz-placeholder { /* Firefox 18- */
  color: gray;  
}
.lightgray textarea:focus::-moz-placeholder {  /* Firefox 19+ */
  color: gray;  
}
.lightgray textarea:focus:-ms-input-placeholder {
  color: gray;  
}
.lightgray textarea:focus::placeholder {
  color: gray;  
}