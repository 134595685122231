.error {
  font-family: UrbanistRegular, Arial, Helvetica, sans-serif;
  color: red;
}

.hint {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #b2b2b2;
}

.required {
  margin-left: 2px;
}

.input {
  font-family: UrbanistMedium, Arial, Helvetica, sans-serif;
  min-width: 5em !important;
  border-radius: 4px !important;
  border: solid 1px #000 !important;
  text-align: center !important;
  min-height: 1em !important;
  width: 100%;
}

.upperCase input {
  text-transform: uppercase;
}